import { stringify } from 'query-string'
import { FormMessage } from '../../components/forms/types'
import { Region, SupportedLanguage } from '../../components/types/gopay'
import { DayStatistics, IqInterval, ResultsData, Statistics } from '../../components/types/iqTests'
import { HistoryState } from '../../components/types/types'
import { Xhr } from '../types'

export type IqTestsModule = ReturnType<typeof createModule>

export type IntervalResultParams = {
  answers: HistoryState['answers']
  answersTime: HistoryState['answersTime']
  age: string
  testId: string
  email: string
  language: SupportedLanguage
  region: Region
}

export type CertificateParams = {
  email: string
  orderEmail: string
  paymentId: number | string
  name: string
  surname: string
}

export function createModule(xhr: Xhr) {
  return {
    getIqInterval: (params: IntervalResultParams) => {
      return xhr.post<IqInterval>(`/results/iq/interval`, params)
    },

    getSaleIqInterval: (id: number | string) => {
      return xhr.get<IqInterval>(`/results/iq/interval?${stringify({ id })}`)
    },

    getResults: (paymentId: string | number) => {
      return xhr.get<ResultsData>(`/results/${paymentId}`)
    },

    getStatistics: (psw: string, fromTime?: string) => {
      const query = fromTime ? `?timeFrom=${fromTime}` : ''
      return xhr.get<Statistics>(`/statistics/${psw}${query}`)
    },

    getDayStatistics: (psw: string, region: Region, timeFrom?: string) => {
      const query = {
        region,
        timeFrom
      }
      return xhr.get<DayStatistics>(`/statistics/${psw}?${stringify(query)}`)
    },

    putCertificate: (parameters: CertificateParams) => {
      const { paymentId, ...body } = parameters
      return xhr.put(`/certificates/${paymentId}`, body)
    },

    sendQuestionMessage: (data: FormMessage) => {
      return xhr.post(`/messages/form/question`, data)
    }
  }
}
