export enum Currency {
  CZK = 'CZK',
  EUR = 'EUR',
  PLN = 'PLN',
  USD = 'USD',
  GBP = 'GBP',
  HUF = 'HUF',
  RON = 'RON',
  BGN = 'BGN',
  HRK = 'HRK'
}

export enum SupportedLanguage {
  CS = 'CS',
  SK = 'SK',
  EN = 'EN',
  DE = 'DE',
  RU = 'RU',
  PL = 'PL',
  HU = 'HU',
  RO = 'RO',
  BG = 'BG',
  HR = 'HR',
  IT = 'IT',
  FR = 'FR',
  ES = 'ES',
  UK = 'UK'
}

export enum Region {
  CZ = 'CZ', // cesko
  GB = 'GB', // anglie
  US = 'US' // amerika
}
