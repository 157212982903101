import { Option } from '../../components/blocks/types'
import { EvaluateOptions } from '../../components/results/types'
import { Currency, Region, SupportedLanguage } from '../../components/types/gopay'
import { isProduction } from '../../utils/devices'
import { Xhr } from '../types'

export type GoPayModule = ReturnType<typeof createModule>

export type GetGwUrlParams = {
  reportType: EvaluateOptions
  paymentType: Option
  env?: 'localhost'
  currency: Currency
  language: SupportedLanguage
  region: Region
  id: string | number
  withSale?: boolean
}

export function createModule(xhr: Xhr) {
  return {
    getGwUrl: (params: GetGwUrlParams) => {
      if (!params.env && !isProduction()) {
        params.env = 'localhost'
      }
      return xhr.post<{ gwUrl: string }>(`/payment/create`, params)
    }
  }
}
