import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { createApiClient } from './api/createApiClient'
import { ApiClientContext } from './hooks/useApiClient'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
// import { initFonts } from './utils/font'
import { initializeGa } from './utils/gaUtils'
import { initLocalization, loadTranslations } from './utils/i18t'
import './index.css'
import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import { getCurrentLanguage } from './utils/langUtils'

const Router = lazy(() => import('./Router'))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: err => {
        console.log('React query error: ', err)
      }
    },
    mutations: {
      onError: err => {
        console.log('React query error - mutation: ', err)
      }
    }
  }
})

const translations = initLocalization('cs', loadTranslations(getCurrentLanguage()))
// initFonts()
initializeGa('G-CMVW8MJW1T')

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={translations}>
        <ApiClientContext.Provider value={createApiClient()}>
          <BrowserRouter>
            <Suspense fallback={null}>
              <Router />
            </Suspense>
          </BrowserRouter>
        </ApiClientContext.Provider>
      </I18nextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
