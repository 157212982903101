import { createModule as createGoPayModule } from './modules/gopay'
import { createModule as createIqTestModuleModule } from './modules/iqTests'
import { ApiClient } from './types'
import { createXhr } from './xhr'
import { isProductionAPI } from '../utils/devices'

export function createApiClient(baseUrl = ''): ApiClient {
  const defaultUrl = isProductionAPI() ? 'https://backend.iq-testy.online' : 'https://test.iq-testy.online'

  const xhr = createXhr(baseUrl || defaultUrl)

  const iqTest = createIqTestModuleModule(xhr)
  const gopay = createGoPayModule(xhr)

  return {
    xhr,
    baseUrl,
    iqTest,
    gopay
  }
}
