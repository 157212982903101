import i18next from 'i18next'
import ICU from 'i18next-icu'
import { initReactI18next } from 'react-i18next'
import { SupportedLanguage } from '../components/types/gopay'

type Bundle = {
  [key: string]: string
}

export function loadTranslations(lang: SupportedLanguage) {
  /* eslint @typescript-eslint/no-var-requires: "off" */
  const bundle = require(`../translations/${lang}/bundle.json`)
  return bundle
}

export function initLocalization(language: string, translations: Bundle) {
  i18next
    .use(initReactI18next)
    .use(ICU)
    .init({
      returnNull: false,
      initImmediate: false,
      lng: language,
      parseMissingKeyHandler: key => `????? ${key} ?????`,
      interpolation: {
        prefix: '{',
        suffix: '}'
      },
      nsSeparator: false,
      keySeparator: false,
      resources: {
        [language]: {
          translation: translations
        }
      }
    })
  return i18next
}
