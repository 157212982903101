import ReactGa from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'
import { isProduction, isProductionAPI } from './devices'

export function logGa(args: UaEventOptions) {
  if (isProductionAPI() && isProduction()) {
    ReactGa.event(args)
  }
}

export function logGaPage(path: string) {
  if (isProduction() && isProductionAPI()) {
    ReactGa.send({ hitType: 'pageview', page: path })
  }
}

export async function initializeGa(id: string) {
  const { default: reactGa } = await import('react-ga4')
  if (!reactGa.isInitialized) {
    reactGa.initialize(id)
  }
}
