import Bowser from 'bowser'

function createParser() {
  return Bowser.getParser(window.navigator.userAgent)
}

export function isIos() {
  return createParser().getOSName() === Bowser.OS_MAP.iOS
}

export function isAndroid() {
  return createParser().getOSName() === Bowser.OS_MAP.Android
}

export function isStandAlone() {
  return window.matchMedia('(display-mode: standalone)').matches || document.referrer.includes('android-app://')
}

export function isDesktop() {
  return createParser().getPlatformType() === Bowser.PLATFORMS_MAP.desktop
}

export function isProduction() {
  return process.env.NODE_ENV && process.env.NODE_ENV === 'production'
}

export function isProductionAPI() {
  return process.env.REACT_APP_API_ENV && process.env.REACT_APP_API_ENV === 'production'
}

export function isTestMode() {
  return process.env.REACT_APP_MODE && process.env.REACT_APP_MODE === 'test'
}
